function createCanonicalElement() {
    var link = document.createElement("LINK");
    var attRel = document.createAttribute("rel");
    var attHref = document.createAttribute("href");
    attRel.value = "canonical";
    attHref.value = window.location.href;
    link.setAttributeNode(attRel);
    link.setAttributeNode(attHref);
    document.head.appendChild(link);
}

createCanonicalElement();
